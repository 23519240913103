import Environments from "views/admin/Environments.js";
import CategoriesLanguages from "views/admin/CategoriesLanguages.js";
import Categories from "views/admin/Categories";
import SongLanguages from "views/admin/SongLanguages.js";
import Songs from "views/admin/Songs";

var dashRoutes = [
  {
    path: "/environments",
    name: "ENVIRONMENTS",
    icon: "fa fa-file-code-o",
    component: Environments,
    layout: "/admin",
  },
  {
    path: "/catlanguages",
    name: "Category Languages",
    icon: "fa fa-language",
    component: CategoriesLanguages,
    layout: "/admin",
  },
  {
    path: "/categories",
    name: "categories",
    icon: "fa fa-th-list",
    component: Categories,
    layout: "/admin",
  },
  {
    path: "/songlanguages",
    name: "Song Languages",
    icon: "fa fa-language",
    component: SongLanguages,
    layout: "/admin",
  },
  {
    path: "/songs",
    name: "Songs",
    icon: "fa fa-music",
    component: Songs,
    layout: "/admin",
  },
];
export default dashRoutes;
