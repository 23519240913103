import React from "react";
import { useTable, useSortBy } from "react-table";
import BTable from "react-bootstrap/Table";

function Table({ columns, data, updateMyData, removeRow, saveChanges }) {
  const table = useTable(
    {
      columns,
      data,
      updateMyData,
      removeRow,
      saveChanges,
    },
    useSortBy
  );

  const { getTableProps, headerGroups, prepareRow, rows } = table;

  return (
    <>
      <BTable
        striped
        bordered
        size="sm"
        {...getTableProps()}
        className="menu-detail-list"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  {column.Header !== "" && (
                    <span style={{ color: "#f63a80" }}>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <i
                            className="fa fa-angle-down"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i className="fa fa-angle-up" aria-hidden="true"></i>
                        )
                      ) : (
                        <>
                          <i className="fa fa-angle-up" aria-hidden="true"></i>
                          <i
                            className="fa fa-angle-down"
                            aria-hidden="true"
                          ></i>
                        </>
                      )}
                    </span>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
    </>
  );
}

const DeleteAction = (props) => {
  const { row, removeRow } = props;
  return (
    <>
      {/* <UncontrolledTooltip delay={0} target={'ttd-' + row.id}>
        Ürün Grubunu Siler. Altında ürün olan Ürün Gruplarını silemezsiniz.
      </UncontrolledTooltip> */}
      <span
        id={"ttd-" + row.id}
        onClick={() => removeRow(row.index)}
        role="img"
        aria-label="delete"
        className={"action-item"}
      >
        <i className="fa fa-times"></i>
      </span>
    </>
  );
};

const ActionCell = (props) => {
  return <DeleteAction {...props} />;
};

const UneditableCell = (props) => {
  return (
    <span className="text-muted no-edit table-item-al ">{props.value}</span>
  );
};
function LanguageList(props) {
  const columns = React.useMemo(() => {
    return [
      {
        Header: "Language Full Name",
        accessor: "_id",
        Cell: UneditableCell,
      },
      {
        Header: "Language",
        accessor: "name",
        Cell: UneditableCell,
      },
      {
        Header: "Action",
        id: "action",
        Cell: ActionCell,
      },
    ];
  });

  const data = props.data ? props.data : [];

  const updateMyData = (rowIndex, columnID, newValue) => {
    props.saveChanges(rowIndex, columnID, newValue);
    props.setData((oldData) =>
      oldData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...oldData[rowIndex],
            [columnID]: newValue,
          };
        }
        return row;
      })
    );
  };

  const removeRow = (rowIndex) => {
    props.setData((old) => old.filter((row, index) => index !== rowIndex));
    props.deleteLanguage(rowIndex);
  };

  return (
    <>
      <Table
        columns={columns}
        data={data}
        updateMyData={updateMyData}
        removeRow={removeRow}
      />
    </>
  );
}

export default LanguageList;
