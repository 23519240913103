import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ConfirmationModal = (props) => {
  const {
    className,
    confirmation,
    toggleConfirmation,
    title,
    subtitle,
    body,
    continueAction,
  } = props;

  return (
    <div>
      <Modal
        isOpen={confirmation}
        toggle={toggleConfirmation}
        className={className}
      >
        <ModalHeader toggle={toggleConfirmation}>
          {title}
          {subtitle && (
            <span className="ml-3 text-muted" style={{ fontSize: "80%" }}>
              {subtitle}
            </span>
          )}
        </ModalHeader>
        <hr />
        <ModalBody>
          {body}
          <br />
          <br />
          Continue?
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn-block btn-mid"
            color="primary"
            onClick={continueAction}
            type="button"
          >
            Continue
          </Button>
          <Button
            className="btn-block btn-mid"
            color="danger"
            onClick={toggleConfirmation}
            type="button"
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
