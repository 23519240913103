import React from 'react';
import RingLoader from 'react-spinners/RingLoader';

const LoaderRing = () => (
  <div className="spinner-container">
    <div className="inner">
    <RingLoader size={50} loading={true} color="white" />
    </div>
  </div>
);

export default LoaderRing;
