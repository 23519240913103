import React from "react";
import RiseLoader from "react-spinners/RotateLoader";

const EnvironmentLoader = () => (
  <div className="spinner-container">
    <div className="center">
      <RiseLoader size={25} loading={true} color="#7e2016" />
      <RiseLoader size={25} loading={true} color="#7e2016" />
    </div>
  </div>
);

export default EnvironmentLoader;
