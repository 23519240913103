export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getUser = () => {
  return localStorage.getItem("mquser")
    ? JSON.parse(localStorage.getItem("mquser"))
    : null;
};

export const getUsername = () => {
  return localStorage.getItem("mquser")
    ? JSON.parse(localStorage.getItem("mquser")).username
    : null;
};

export const getTokenExpired = () => {
  return !localStorage.getItem("mqtokenexpired") ||
    localStorage.getItem("mqtokenexpired") === "0"
    ? false
    : true;
};
