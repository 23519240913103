import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/ui-dashboard-css.scss";
import "assets/scss/ui-css.scss";

import App from "./App";
import ServerError from "views/ServerError.js";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/server-error" render={(props) => <ServerError />} />
      <App />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
