import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// reactstrap components

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  CardFooter,
} from "reactstrap";

import PanelHeader from "components/Headers/PanelHeader.js";
import SongListCategory from "components/Lists/SongListCategory.js";

import CRUD from "gapi/crud";

function Categories(props) {
  const history = useHistory();
  const [selectedEnvironmentId, setSelectedEnvironmentId] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [data, setData] = useState([]);
  const [songs, setSongs] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);

  const id = localStorage.getItem("selected-environment-id");
  React.useEffect(() => {
    setSelectedEnvironmentId(id);
  }, [id]);

  const changeOption = async (value) => {
    setSelectedCategory(value);
    const ss = data.songs[value].filter((k) => k != null);
    setSongs(ss);
  };

  const saveNewOrder = async (newData) => {
    props.setSpinner(true);
    CRUD.songsreorder.update(
      history,
      selectedEnvironmentId + "/" + selectedLanguage,
      newData,
      (res) => {
        data.songs[selectedCategory] = res.data;
        setData({ ...data });
        setSongs([...res.data]);
        props.notify("Song Reordered Successfully.", "success");
        setTimeout(() => {
          props.setSpinner(false);
        }, 500);
      },
      (err) => {
        props.notify(err);
        props.setSpinner(false);
      }
    );
  };

  const deleteItem = async (index) => {
    props.setSpinner(true);
    CRUD.songs.delete(
      history,
      selectedEnvironmentId + "/" + selectedLanguage + "/" + songs[index].id,
      (res) => {
        if (res && res.data) {
          let nsongs = songs.filter((x) => x.id !== songs[index].id);
          console.log(nsongs);
          data.songs[selectedCategory] = [...nsongs];
          setData({ ...data });
          setSongs([...nsongs]);
          props.notify("Song Deleted Successfully", "success");
        }
        setTimeout(() => {
          props.setSpinner(false);
        }, 500);
      },
      (err) => {
        props.notify(err);
        props.setSpinner(false);
      }
    );
  };

  const addItem = () => {
    props.setSpinner(true);
    CRUD.songs.createWithId(
      history,
      selectedEnvironmentId + "/" + selectedLanguage,
      {
        id: data.newID,
        categoryId: selectedCategory,
        orderInCategory: songs.length + 1,
      },
      (res) => {
        if (res && res.data) {
          data.songs[selectedCategory].push(res.data);
          data.newID++;
          setData({ ...data });
          setSongs([...songs, res.data]);
          props.notify(
            "Song Created Successfully, Please edit fields",
            "success"
          );
        }
        setTimeout(() => {
          props.setSpinner(false);
        }, 500);
      },
      (err) => {
        props.notify(err);
        props.setSpinner(false);
      }
    );
  };

  const saveChanges = async (index, field, value) => {
    console.log(index);
    console.log(field);
    console.log(value);

    if (field === -1 || songs[index][field] !== value) {
      props.setSpinner(true);
      let updateData;
      if (field === -1) {
        updateData = { ...songs[index], changes: value };
      } else {
        updateData = { ...songs[index], changes: {} };
        updateData.changes[field] = value;
      }
      CRUD.songs.update(
        history,
        selectedEnvironmentId + "/" + selectedLanguage,
        updateData,
        (res) => {
          if (res && res.data) {
            data.songs[selectedCategory][index] = res.data;
            songs[index] = res.data;
            setData({ ...data });
            setSongs([...songs]);
            props.notify("Song Changed Successfully.", "success");
          }
          setTimeout(() => {
            props.setSpinner(false);
          }, 500);
        },
        (err) => {
          props.notify(err);
          props.setSpinner(false);
        }
      );
    }
  };

  const changeLanguage = async (language) => {
    setSelectedLanguage(language);
    fetchAll(language);
  };

  React.useEffect(() => {
    fetchAll("songs");

    CRUD.songlanguages.get(history, selectedEnvironmentId, (res) => {
      if (res && res.data) {
        setLanguages(res.data);
      }
    });
  }, [selectedEnvironmentId]);

  const fetchAll = (language) => {
    if (selectedEnvironmentId) {
      props.setSpinner(true);
      setSelectedLanguage(language);
      setSelectedCategory(0);
      setSongs([]);
      CRUD.songs.get(
        history,
        selectedEnvironmentId + "/" + (language ? language : selectedLanguage),
        (res) => {
          if (res && res.data) {
            setData(res.data);
            let cats = [];
            Object.entries(res.data.songs).map((key, index) => {
              if (index === 0) {
                setSelectedCategory(key[0]);
                const ss = key[1].filter((k) => k != null);
                setSongs(ss);
              }
              cats.push(key[0]);
            });
            setCategoryData(cats);
            setTimeout(() => {
              props.setSpinner(false);
            }, 500);
          }
        },
        () => {
          setTimeout(() => {
            props.setSpinner(false);
          }, 500);
        }
      );
    }
  };

  return (
    <>
      <PanelHeader
        content={
          <div className="header">
            <h6 className="title mb-">CATEGORIES</h6>
          </div>
        }
      />
      <div className="content">
        <Row>
          {props.Dropdown()}
          <Col md={4}>
            <Card className="left-bordered  pt-3 environmentselect">
              <CardHeader></CardHeader>
              <CardBody className="pt-1">
                <FormControl
                  variant="outlined"
                  className="form-control environment-select"
                >
                  <InputLabel shrink htmlFor="outlined-age-native-simple">
                    Selected Song Language
                  </InputLabel>
                  <Select
                    native
                    label={"Selected Environment"}
                    value={selectedLanguage ? selectedLanguage : "songs"}
                    onChange={(e) => {
                      changeLanguage(e.target.value);
                    }}
                    color="primary"
                  >
                    {languages.map((prop, key) => {
                      return (
                        <option key={key} value={prop._id}>
                          {prop._id}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {selectedEnvironmentId && (
          <Row>
            <Col md={12}>
              <Card className="left-bordered pt-3 items">
                <CardHeader>
                  <Row>
                    <Col md="11">
                      <h6 className="title ml-3">
                        SONG LIST BY CATEGORY
                        <span
                          className="ml-3 text-muted"
                          style={{ fontSize: "80%" }}
                        >
                          Select Category to LIst other Category's Songs.
                        </span>
                      </h6>
                    </Col>
                    <Col md="1">
                      <select
                        className="form-control categorySelect"
                        defaultValue={selectedCategory}
                        onChange={(e) => {
                          e.preventDefault();
                          changeOption(e.target.value);
                        }}
                      >
                        {categoryData.map((prop, key) => {
                          return (
                            <option key={key} value={prop}>
                              {prop}
                            </option>
                          );
                        })}
                      </select>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="mt-3">
                  <Row>
                    <Col md="12">
                      <SongListCategory
                        data={songs}
                        setSongs={setSongs}
                        deleteItem={deleteItem}
                        saveChanges={saveChanges}
                        saveNewOrder={saveNewOrder}
                      />
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Row className="justify-content-center">
                    {selectedEnvironmentId ? (
                      <Col sm="3">
                        <Button
                          className="addproduct"
                          id="caret"
                          color="primary"
                          onClick={(e) => {
                            addItem();
                          }}
                        >
                          Add New Song
                        </Button>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
}

export default Categories;
