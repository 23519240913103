import React, { useState } from "react";

import { useHistory } from "react-router-dom";
// reactstrap components
import TextField from "@material-ui/core/TextField";
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  CardFooter,
  FormGroup,
} from "reactstrap";

import PanelHeader from "components/Headers/PanelHeader.js";
import LanguageList from "components/Lists/LanguageList";

import CRUD from "gapi/crud";

function Languages(props) {
  const history = useHistory();

  const [isWaiting, setWaiting] = useState(false);
  const [selectedEnvironmentId, setSelectedEnvironmentId] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [data, setData] = useState([]);
  const [langName, setLangName] = useState("");

  const id = localStorage.getItem("selected-environment-id");
  React.useEffect(() => {
    setSelectedEnvironmentId(id);
  }, [id]);

  const deleteLanguage = async (index) => {
    props.setSpinner(true);
    CRUD.catlanguages.delete(
      history,
      selectedEnvironmentId + "/" + languages[index]._id,
      (res) => {
        if (res && res.data) {
          var langs = res.data.map((obj) => {
            return {
              ...obj,
              id: "item-" + obj._id,
            };
          });
          var langsC = res.data.map((obj) => {
            return {
              ...obj,
              id: "item-" + obj._id,
            };
          });
          setLanguages(langs);
          setData(langsC);
          props.notify("Delete Language Success", "success");
        }

        props.setSpinner(false);
      },
      (err) => {
        setData([...languages]);
        props.notify(err, "success");

        props.setSpinner(false);
      }
    );
  };

  const addLanguage = () => {
    if (langName.length > 0) {
      props.setSpinner(true);
      const newData = {
        name: langName,
      };

      CRUD.catlanguages.createWithId(
        history,
        selectedEnvironmentId,
        newData,
        (res) => {
          if (res && res.data) {
            var langs = res.data.map((obj) => {
              return {
                ...obj,
                id: "item-" + obj._id,
              };
            });
            var langsC = res.data.map((obj) => {
              return {
                ...obj,
                id: "item-" + obj._id,
              };
            });
            setLanguages(langs);
            setData(langsC);
            props.notify("New Language Copied by EN Success", "success");
          }

          props.setSpinner(false);
        },
        (err) => {
          setData([...languages]);
          props.notify(err);

          props.setSpinner(false);
        }
      );
    }
  };

  React.useEffect(() => {
    if (selectedEnvironmentId) {
      props.setSpinner(true);
      CRUD.catlanguages.get(
        history,
        selectedEnvironmentId,
        (res) => {
          if (res && res.data) {
            var langs = res.data.map((obj) => {
              return {
                ...obj,
                id: "item-" + obj._id,
              };
            });
            var langsC = res.data.map((obj) => {
              return {
                ...obj,
                id: "item-" + obj._id,
              };
            });
            setLanguages(langs);
            setData(langsC);
          }
          setTimeout(() => {
            props.setSpinner(false);
          }, 500);
        },
        () => {
          setTimeout(() => {
            props.setSpinner(false);
          }, 500);
        }
      );
    }
  }, [selectedEnvironmentId]);

  return (
    <>
      <PanelHeader
        content={
          <div className="header">
            <h5 className="title mb-">Category Languages</h5>
          </div>
        }
      />
      <div className="content">
        <Row>{props.Dropdown()}</Row>
        {selectedEnvironmentId && (
          <Row>
            <Col md={12}>
              <Card className="left-bordered  pt-3 productCat">
                <CardHeader>
                  <Row>
                    <Col md="12">
                      <h6 className="title ml-3">
                        CATEGORY LANGUAGE LIST
                        <span
                          className="ml-3 text-muted"
                          style={{ fontSize: "80%" }}
                        >
                          YOU CAN ADD NEW LANGUAGE CONFIGURATION BY ADD BUTTON
                          BELOW - YOU CANNOT CHANGE THE "NAME" AFTER ADDIG IT,
                          YOU NEED TO DELETE AND READD IT.
                        </span>
                      </h6>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="12">
                      <LanguageList
                        data={data}
                        setData={setData}
                        deleteLanguage={deleteLanguage}
                      />
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  {selectedEnvironmentId && (
                    <Row className="justify-content-center">
                      <Col sm="3">
                        <FormGroup className="note">
                          <TextField
                            id="langName"
                            type="text"
                            value={langName}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => {
                              setLangName(e.target.value);
                            }}
                            placeholder={"New Language Name"}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <UncontrolledTooltip delay={0} target="tooltip1">
                          If you add new Language EN categories will be copied,
                          then you can edit categories details on categories
                          page.
                        </UncontrolledTooltip>
                        <Button
                          disabled={isWaiting}
                          id="tooltip1"
                          className="btn-wide newCat"
                          color="primary"
                          size="md"
                          onClick={(e) => addLanguage(e)}
                        >
                          {" "}
                          Add New Language
                        </Button>
                      </Col>
                    </Row>
                  )}
                </CardFooter>
              </Card>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
}

export default Languages;
