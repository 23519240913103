import React from "react";

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// reactstrap components
import { Route, Switch, Redirect } from "react-router-dom";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import CRUD from "gapi/crud";

import routes from "routes.js";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";

// reactstrap components
import { Card, CardBody, Col } from "reactstrap";

var ps;

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "blue",
      selectedEnvironment: {},
      environments: [],
    };
  }
  mainPanel = React.createRef();

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
    this.props.setSpinner(true);
    CRUD.environments.getAll(
      this.props.history,
      (res) => {
        if (res && res.data) {
          this.setState({ environments: res.data.environments });
          this.changeOption(
            this.selectedEnvironmentId
              ? this.selectedEnvironmentId
              : this.state.environments.length > 0
              ? this.state.environments[0]._id
              : 0
          );
          setTimeout(() => {
            this.props.setSpinner(false);
          }, 500);
        }
      },
      () => {
        setTimeout(() => {
          this.props.setSpinner(false);
        }, 500);
      }
    );
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.mainPanel.current.scrollTop = 0;
    }
  }

  handleColorClick = (color) => {
    this.setState({ backgroundColor: color });
  };

  changeOption = async (environmentId) => {
    if (environmentId) {
      this.props.setSpinner(true);
      localStorage.setItem("selected-environment-id", environmentId);
      this.selectedEnvironmentId = environmentId;
      if (environmentId !== 0) {
        CRUD.config.get(
          this.props.history,
          environmentId,
          (res) => {
            if (res && res.data) {
              this.setState({ selectedEnvironment: res.data });
              setTimeout(() => {
                this.props.setSpinner(false);
              }, 500);
            }
          },
          () => {
            setTimeout(() => {
              this.props.setSpinner(false);
            }, 500);
          }
        );
      }
    } else {
      localStorage.removeItem("selected-environment-id");
      this.setState({ selectedEnvironment: {} });
    }
  };

  Dropdown = (subtext) => {
    return (
      <Col md={8}>
        <Card className="left-bordered  pt-3 environmentselect">
          <CardBody className="pt-1">
            <FormControl
              variant="outlined"
              className="form-control environment-select"
            >
              <InputLabel shrink htmlFor="outlined-age-native-simple">
                Selected Environment
              </InputLabel>
              <Select
                native
                label={"Selected Environment"}
                value={this.selectedEnvironmentId}
                onChange={(e) => {
                  this.changeOption(e.target.value);
                }}
                color="primary"
              >
                {this.state.environments.map((prop, key) => {
                  return (
                    <option key={key} value={prop._id}>
                      {prop._id === this.state.selectedEnvironment._id &&
                      prop.name !== this.state.selectedEnvironment.name
                        ? this.state.selectedEnvironment.name
                        : prop.name}
                    </option>
                  );
                })}
              </Select>
              {subtext && (
                <FormHelperText>
                  Select which environment you want to change firebase config
                </FormHelperText>
              )}
            </FormControl>
          </CardBody>
        </Card>
      </Col>
    );
  };

  render() {
    return (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          routes={routes}
          backgroundColor={this.state.backgroundColor}
          selectedEnvironment={this.state.selectedEnvironment}
          selectedEnvironmentId={this.selectedEnvironmentId}
        />
        <div className="main-panel" ref={this.mainPanel}>
          <AdminNavbar {...this.props} />
          <Switch>
            {routes.map((prop, key) => {
              return (
                <Route path={prop.layout + prop.path} key={key}>
                  <prop.component
                    setSpinner={this.props.setSpinner}
                    notify={this.props.notify}
                    selectedEnvironment={this.state.selectedEnvironment}
                    Dropdown={this.Dropdown}
                    changeOption={this.changeOption}
                    addEnvironment={this.addEnvironment}
                    deleteEnvironment={this.deleteEnvironment}
                    environments={this.state.environments}
                  />
                </Route>
              );
            })}
            <Redirect from="/admin" to="/admin/environments" />
          </Switch>
        </div>
        {/* <FixedPlugin
          bgColor={this.state.backgroundColor}
          handleColorClick={this.handleColorClick}
        /> */}
      </div>
    );
  }
}

export default Admin;
