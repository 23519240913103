import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  CardFooter,
} from "reactstrap";

import PanelHeader from "components/Headers/PanelHeader.js";
import CategoryList from "components/Lists/CategoryList.js";

import CRUD from "gapi/crud";

function Categories(props) {
  const history = useHistory();
  const [selectedEnvironmentId, setSelectedEnvironmentId] = useState(null);
  const [data, setData] = useState([]);

  const id = localStorage.getItem("selected-environment-id");
  React.useEffect(() => {
    setSelectedEnvironmentId(id);
  }, [id]);

  const deleteItem = async (index) => {
    props.setSpinner(true);
    CRUD.categories.delete(
      history,
      selectedEnvironmentId + "/" + data[index].id,
      (res) => {
        if (res && res.data) {
          setData(res.data.categories);
          props.notify("Category Deleted Successfully", "success");
        }
        setTimeout(() => {
          props.setSpinner(false);
        }, 500);
      },
      (err) => {
        props.notify(err);
        props.setSpinner(false);
      }
    );
  };

  const addItem = () => {
    props.setSpinner(true);

    CRUD.categories.createWithId(
      history,
      selectedEnvironmentId,
      {},
      (res) => {
        if (res && res.data) {
          setData(res.data.categories);
          props.notify(
            "Category Created Successfully, Please edit all fields",
            "success"
          );
        }
        setTimeout(() => {
          props.setSpinner(false);
        }, 500);
      },
      (err) => {
        props.notify(err);
        props.setSpinner(false);
      }
    );
  };

  const saveChanges = async (index, field, value) => {
    if (field === -1 || data[index][field] !== value) {
      props.setSpinner(true);
      let updateData;
      if (field === -1) {
        updateData = { ...data[index], changes: value };
      } else {
        updateData = { ...data[index], changes: {} };
        updateData.changes[field] = value;
      }
      CRUD.categories.update(
        history,
        selectedEnvironmentId,
        updateData,
        (res) => {
          if (res && res.data) {
            setData(res.data.categories);
            props.notify("Category Changed Successfully.", "success");
          }
          setTimeout(() => {
            props.setSpinner(false);
          }, 500);
        },
        (err) => {
          props.notify(err);
          props.setSpinner(false);
        }
      );
    }
  };

  React.useEffect(() => {
    fetchAll();
  }, [selectedEnvironmentId]);

  const fetchAll = () => {
    if (selectedEnvironmentId) {
      props.setSpinner(true);
      CRUD.config.get(
        history,
        selectedEnvironmentId,
        (res) => {
          if (res && res.data) {
            setData(res.data.categories);
          }
          setTimeout(() => {
            props.setSpinner(false);
          }, 500);
        },
        () => {
          setTimeout(() => {
            props.setSpinner(false);
          }, 500);
        }
      );
    }
  };

  return (
    <>
      <PanelHeader
        content={
          <div className="header">
            <h6 className="title mb-">CATEGORIES</h6>
          </div>
        }
      />
      <div className="content">
        <Row>{props.Dropdown()}</Row>
        {selectedEnvironmentId && (
          <Row>
            <Col md={12}>
              <Card className="left-bordered pt-3 items">
                <CardHeader>
                  <Row>
                    <Col md="12">
                      <h6 className="title ml-3">
                        CATEGORY LIST
                        <span
                          className="ml-3 text-muted"
                          style={{ fontSize: "80%" }}
                        >
                          Same category IN all langs
                        </span>
                      </h6>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="mt-3">
                  <Row>
                    <Col md="12">
                      <CategoryList
                        data={data}
                        setData={setData}
                        deleteItem={deleteItem}
                        saveChanges={saveChanges}
                      />
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Row className="justify-content-center">
                    {selectedEnvironmentId ? (
                      <Col sm="3">
                        <Button
                          className="addproduct"
                          id="caret"
                          color="primary"
                          onClick={(e) => {
                            addItem();
                          }}
                        >
                          Add New Category
                        </Button>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
}

export default Categories;
