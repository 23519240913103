export const base = process.env.REACT_APP_API_BASE_URL;

export const API = {
  login: "/auth/login",
  renew: "/auth/renew",
  renewInfo: "/auth/renewInfo",

  subscriptionRequest: "/subscription/createSubscriptionRequest",

  environments: "/config/environments",
  config: "/config",
  categories: "/cat",
  catlanguages: "/catlang",
  songlanguages: "/songlang",
  songs: "/song",
  songsreorder: "/song/reorder",
};

const Endpoints = {};

Object.keys(API).map((it) => {
  Endpoints[it] = base + "/api" + API[it];
  return it;
});

export default Endpoints;
