import React, { useState } from "react";

import { withRouter, Route, Redirect, Switch } from "react-router-dom";
import NotificationAlert from "react-notification-alert";

import LoaderRing from "components/Loaders/LoaderRing";

import AdminLayout from "./views/Admin.js";

import LockScreen from "views/auth/LockScreen.js";
import Login from "views/auth/Login.js";

import { getUser, getTokenExpired } from "functions/functions";

const App = () => {
  const [spinner, setSpinner] = useState(false);
  const notificationAlertObject = React.useRef(null);

  React.useEffect(() => {}, [spinner]);

  const notify = (notificationMessage, type = "danger", place = "tc") => {
    var options = {
      place: place,
      message: <p className="mt-2">{notificationMessage}</p>,
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 3,
    };
    notificationAlertObject.current &&
      notificationAlertObject.current.notificationAlert(options);
  };
  return (
    <>
      {spinner && <LoaderRing />}
      <NotificationAlert ref={notificationAlertObject} />
      {getUser() ? (
        getTokenExpired() ? (
          <Switch>
            <Route
              path="/lock"
              render={(props) => (
                <LockScreen
                  {...props}
                  setSpinner={setSpinner}
                  notify={notify}
                />
              )}
            />
            <Redirect to="/lock" />
            <Redirect from="/" to="/lock" />
          </Switch>
        ) : (
          <Switch>
            <Route
              path="/admin"
              render={(props) => (
                <AdminLayout
                  {...props}
                  setSpinner={setSpinner}
                  notify={notify}
                />
              )}
            />
            <Redirect to="/admin/environments" />
            <Redirect from="/" to="/admin/environments" />
          </Switch>
        )
      ) : (
        <Switch>
          <Route
            path="/login"
            render={(props) => (
              <Login {...props} setSpinner={setSpinner} notify={notify} />
            )}
          />
          <Redirect from="/*" to="/login" />
        </Switch>
      )}
    </>
  );
};

export default withRouter(App);
