import React from "react";

import EnvironmentLoadingHeader from "components/Headers/EnvironmentLoadingHeader.js";
import PanelNavbar from "components/Navbars/PanelNavbar.js";

function ServerError() {
  React.useEffect(() => {
    document.body.style.overflow = "hidden";
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <PanelNavbar />
      <div className="wrapper">
        <EnvironmentLoadingHeader serverError={true} />
      </div>
    </>
  );
}

export default ServerError;
