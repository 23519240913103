import Endpoints from './Endpoints';
import { Cookies } from 'react-cookie-consent';
/**
 * @description Generic request method for CRUD operations with validation.
 * @param {string} name Endpoint name
 * @param {string} method Request method type (GET, POST, PUT or DELETE)
 * @param {string | number} id Request resource id
 * @param {string | object} body Request body
 * @param {func} onSuccess Callback for request success
 * @param {func} onFail Callback for request failure
 */

const request = async (
  history,
  name,
  method = 'GET',
  id = null,
  body = null,
  onSuccess = () => { },
  onFail = () => { },
  params = null,
) => {
  try {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('mqtoken'),
    };
    if (body && typeof body !== 'string') body = JSON.stringify(body);

    const url = `${Endpoints[name]}${(id && `/${id}`) || ''}${(params && `${params}`) || ''}`;
    var res = await fetch(url, { method, headers, body });
    if (!res.ok) {
      res.json().then((json) => {
        if (json.message === 'jwt expired') {
          localStorage.setItem('mqtokenexpired', '1');
          history.push('/lock');
        } else if (json.message === 'No customer') {
          Cookies.set('login', false);
          //window.location.reload();
        }

        onFail(json.message);
      });
      return;
    }
    if (res) {
      res
        .json()
        .then((json) => onSuccess(json))
        .catch((e) => onFail(e.message));
    } else onSuccess();
  } catch (err) {
    onFail(err.message);
    history.push('/server-error');
  }
};

const requestBlob = async (
  history,
  name,
  method = 'POST',
  id = null,
  onSuccess = () => { },
  onFail = () => { },
  params = null,
) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('mqtoken'),
    };

    const url = `${Endpoints[name]}${(id && `/${id}`) || ''}${(params && `${params}`) || ''}`;
    var res = await fetch(url, {
      method,
      headers,
      observe: 'response',
      responseType: 'blob',
    });
    if (!res.ok) {
      res.json().then((json) => {
        if (json.message === 'jwt expired') {
          localStorage.setItem('mqtokenexpired', '1');
          history.push('/lock');
        }

        onFail(json.message);
      });
      return;
    }
    if (res) {
      res
        .blob()
        .then((resObj) => onSuccess(resObj))
        .catch((e) => onFail(e.message));
    } else onSuccess();
  } catch (err) {
    onFail(err.message);
    history.push('/server-error');
  }
};

const CRUD = {};

Object.keys(Endpoints).map((key) => {
  CRUD[key] = {
    get: (history, id, onSuccess, onFail = () => { }, params) =>
      id
        ? request(history, key, 'GET', id, null, onSuccess, onFail, params)
        : onFail(new Error('Id is required!')),
    postBlob: (history, id, onSuccess, onFail = () => { }, params) =>
      id
        ? requestBlob(history, key, 'POST', id, onSuccess, onFail, params)
        : onFail(new Error('Id is required!')),
    getAll: (history, onSuccess, onFail) =>
      request(history, key, 'GET', null, null, onSuccess, onFail),
    create: (history, body, onSuccess, onFail = () => { }) =>
      body
        ? request(history, key, 'POST', null, body, onSuccess, onFail)
        : onFail(new Error('Body is required!')),
    post: (history, body, onSuccess, onFail = () => { }, params) =>
      body
        ? request(history, key, 'POST', null, body, onSuccess, onFail, params)
        : onFail(new Error('Body is required!')),
    createWithId: (history, id, body, onSuccess, onFail = () => { }) =>
      id
        ? request(history, key, 'POST', id, body, onSuccess, onFail)
        : onFail(new Error('Id is required!')),
    update: (history, id, body, onSuccess, onFail = () => { }) =>
      body
        ? request(history, key, 'PUT', id, body, onSuccess, onFail)
        : onFail(new Error('Id and body are required!')),
    updateWithoutId: (history, body, onSuccess, onFail = () => { }) =>
      body
        ? request(history, key, 'PUT', null, body, onSuccess, onFail)
        : onFail(new Error('Body is required!')),
    patch: (history, id, body, onSuccess, onFail = () => { }) =>
      body
        ? request(history, key, 'PATCH', id, body, onSuccess, onFail)
        : onFail(new Error('Id and body are required!')),
    delete: (history, id, onSuccess, onFail = () => { }) =>
      id
        ? request(history, key, 'DELETE', id, null, onSuccess, onFail)
        : onFail(new Error('Id is required!')),
  };
  return key;
});

// Custom endpoints should come to here and merge with CRUD object

export default CRUD;
