var result = {
  isValid: true,
  className: "valid",
};

function setResultInvalid(result, messages) {
  var inValidResult = {
    isValid: false,
    className: "invalid",
    message: messages || "incorrectEntry",
  };
  return inValidResult;
}

/**
 * Validate username field value
 * @param {string} value Username to validate
 * @param {object} messages Field validation messages object { valid: '...', invalid: '...' }
 * @returns {object} { isValid: bool, message: string }
 */
export const isUsername = (value, messages) => {
  var sQtext = "[^\\x0d\\x22\\x5c\\x80-\\xff]";
  var sDtext = "[^\\x0d\\x5b-\\x5d\\x80-\\xff]";
  var sAtom =
    "[^\\x00-\\x20\\x22\\x28\\x29\\x2c\\x2e\\x3a-\\x3c\\x3e\\x40\\x5b-\\x5d\\x7f-\\xff]+";
  var sQuotedPair = "\\x5c[\\x00-\\x7f]";
  var sDomainLiteral = "\\x5b(" + sDtext + "|" + sQuotedPair + ")*\\x5d";
  var sQuotedString = "\\x22(" + sQtext + "|" + sQuotedPair + ")*\\x22";
  var sDomain_ref = sAtom;
  var sSubDomain = "(" + sDomain_ref + "|" + sDomainLiteral + ")";
  var sWord = "(" + sAtom + "|" + sQuotedString + ")";
  var sDomain = sSubDomain + "(\\x2e" + sSubDomain + ")*";
  var sLocalPart = sWord + "(\\x2e" + sWord + ")*";
  var sAddrSpec = sLocalPart + "\\x40" + sDomain; // complete RFC822 username address spec
  var sValidUsername = "^" + sAddrSpec + "$"; // as whole string

  var regex = new RegExp(sValidUsername);
  if (value && regex.test(value)) {
    return result;
  }

  return setResultInvalid(result, messages);
};

/**
 * Validate password field value
 * @param {string} value Password value to validate
 * @param {object} messages Field validation messages object { valid: '...', invalid: '...' }
 * @returns {object} { isValid: bool, message: string }
 */
export const isPass = (value, messages) => {
  const regex = new RegExp(
    `^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}$)(?=[^\\s])(?=[^\\s]+$)`
  );
  if (value && regex.test(value)) {
    return result;
  }

  return setResultInvalid(result, messages || "invalidPassEntry");
};

export const isPass2 = (value, value2) => {
  if (value === value2) {
    return result;
  }

  return setResultInvalid(result, "passNotMatch");
};

export const isEmpty = (value, messages) => {
  if (value) {
    return result;
  }
  return setResultInvalid(result, messages);
};

export const isPhone = (value, messages) => {
  const regex = new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/);
  if (value && regex.test(value) && value.length >= 11) {
    return result;
  }
  return setResultInvalid(result, messages || "invalidPhoneEntry");
};
