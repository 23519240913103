import React from "react";

// reactstrap components
import { Container, Row } from "reactstrap";
import LoaderEnvironment from "components/Loaders/LoaderEnvironment";

function EnvironmentLoadingHeader(props) {
  React.useEffect(() => {
    document.body.style.overflow = "hidden";

    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  return (
    <>
      {!props.serverError && <LoaderEnvironment />}
      <div
        className="page-header login-page-header clear-filter"
        filter-color="main"
      >
        <div className="page-header-image"></div>
        <div className="content login-content">
          <Container>
            <Row className="justify-content-center">
              <div className="center">SERVER ERROR - GO BACK</div>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default EnvironmentLoadingHeader;
