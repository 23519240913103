import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  FormGroup,
  Container,
  Col,
  Row,
} from "reactstrap";

// core components
import LockNavbar from "components/Navbars/LockNavbar.js";
import { isPass } from "functions/validations";
import { getUsername } from "functions/functions";
import CRUD from "gapi/crud";

function LockScreen(props) {
  const history = useHistory();

  const [password, setPassword] = useState("");
  const [isPassValid, setIsPassValid] = useState({});

  const [isHide, setHide] = useState(true);
  const hideSwitch = () => {
    setHide(!isHide);
  };

  React.useEffect(() => {
    document.body.style.overflow = "hidden";
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    const isPassValid = isPass(password, "Invalid password");
    setIsPassValid(isPassValid);

    //if (isPassValid.isValid) {
    props.setSpinner(true);
    CRUD.renew.post(
      "",
      { username: getUsername(), password },
      (res) => {
        if (res) {
          localStorage.setItem("mqtoken", res.token);
          localStorage.setItem("mqtokenexpired", "0");
          setTimeout(() => {
            props.setSpinner(false);
          }, 500);
          history.push("/admin/environments");
        } else {
          props.notify("res is null");
        }
      },
      (err) => {
        setTimeout(() => {
          props.setSpinner(false);
          props.notify(err);
        }, 500);
      }
    );
    // }
  };

  return (
    <>
      <LockNavbar />
      <div className="page-header clear-filter" filter-color="main">
        <div className="page-header-image"></div>
        <div className="content">
          <Container>
            <Col lg="4" md="8" className="mr-auto ml-auto col-12">
              <Card className="card-lock text-center">
                <Form onSubmit={(e) => handleOnSubmit(e)}>
                  <CardHeader>
                    <Row className="justify-content-center">
                      <div className="qrcode"></div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <h4 className="card-title">{getUsername()}</h4>

                    <FormGroup>
                      <Input
                        placeholder="(*) Şifre"
                        className={isPassValid.className}
                        type={isHide ? "password" : "input"}
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          setIsPassValid({});
                        }}
                      ></Input>
                      <i
                        className={
                          "password__show fa fa-eye" + (isHide ? "-slash" : "")
                        }
                        onClick={hideSwitch}
                      />
                    </FormGroup>
                  </CardBody>
                  <CardFooter>
                    <Row>
                      <Col md="12">
                        <Button
                          block
                          color="environment"
                          onClick={(e) => handleOnSubmit(e)}
                          size="md"
                        >
                          login
                        </Button>
                      </Col>
                    </Row>
                  </CardFooter>
                </Form>
              </Card>
            </Col>
          </Container>
        </div>
      </div>
    </>
  );
}

export default LockScreen;
