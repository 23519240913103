import React, { useState } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { useTable } from "react-table";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import BTable from "react-bootstrap/Table";
import Checkbox from "@material-ui/core/Checkbox";
import ConfirmationModal from "components/Modals/Confirmation";

// reactstrap components
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  FormGroup,
  Col,
} from "reactstrap";

const Tr = styled.tr`
  background-color: white;
  display: ${({ isDragging }) => (isDragging ? "table" : "")};
`;

function Table({
  columns,
  data,
  updateMyData,
  reorderData,
  removeRow,
  toggleEditModal,
  toggleDeleteConfirmationModal,
  saveChanges,
}) {
  const table = useTable({
    columns,
    data,
    updateMyData,
    reorderData,
    removeRow,
    toggleEditModal,
    toggleDeleteConfirmationModal,
    saveChanges,
  });

  const { getTableProps, headerGroups, prepareRow, rows } = table;

  const handleDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    reorderData(source.index, destination.index);
  };

  return (
    <>
      <BTable
        striped
        bordered
        size="sm"
        {...getTableProps()}
        className="menu-detail-list"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="table-body">
            {(provided, snapshot) => (
              <tbody ref={provided.innerRef} {...provided.droppableProps}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <Draggable
                      draggableId={row.original.id.toString()}
                      key={row.original.id}
                      index={row.index}
                    >
                      {(provided, snapshot) => {
                        return (
                          <Tr
                            {...row.getRowProps()}
                            {...provided.draggableProps}
                            // {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            isDragging={snapshot.isDragging}
                          >
                            {row.cells.map((cell) => (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell", {
                                  dragHandleProps: provided.dragHandleProps,
                                  isSomethingDragging: snapshot.isDraggingOver,
                                })}
                              </td>
                            ))}
                          </Tr>
                        );
                      }}
                    </Draggable>
                  );
                })}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </BTable>
    </>
  );
}

const DeleteAction = (props) => {
  const { row, toggleDeleteConfirmationModal } = props;
  return (
    <>
      {/* <UncontrolledTooltip delay={0} target={'ttd-' + row.id}>
        Ürün Grubunu Siler. Altında ürün olan Ürün Gruplarını silemezsiniz.
      </UncontrolledTooltip> */}
      <span
        id={"ttd-" + row.id}
        onClick={() => toggleDeleteConfirmationModal(row.index, row.original)}
        role="img"
        aria-label="delete"
        className="action-item"
      >
        <i className="fa fa-times"></i>
      </span>
    </>
  );
};

const OrderArrow = (props) => {
  const { row } = props;
  return (
    <>
      {/* <UncontrolledTooltip delay={0} target={'tto-' + row.id}>
        Ürün Grupları Menünüzde aşağıdaki sıralama ile görünür. Sıralamayı
        sükleyip bırakarak değiştirebilirsiniz.
      </UncontrolledTooltip> */}
      <span
        id={"tto-" + row.id}
        {...props.dragHandleProps}
        className="table-order-button"
        aria-label="move"
        role="img"
      >
        <i className="fa fa-angle-up" aria-hidden="true"></i>
        <i className="fa fa-angle-down" aria-hidden="true"></i>
      </span>
    </>
  );
};

const EditAction = (props) => {
  const { row, toggleEditModal } = props;
  return (
    <>
      {/* <UncontrolledTooltip delay={0} target={'ttd-' + row.id}>
        Ürün Grubunu Siler. Altında ürün olan Ürün Gruplarını silemezsiniz.
      </UncontrolledTooltip> */}
      <span
        id={"ttd-" + row.id}
        onClick={() => toggleEditModal(row.index, row.original)}
        role="img"
        aria-label="edit"
        className="action-item"
      >
        <i className="fa fa-edit"></i>
      </span>
    </>
  );
};

const EditableTextCell = (props) => {
  const { column, row, cell, updateMyData } = props;
  const value = cell.value;
  const rowIndex = row.index;
  const columnId = column.id;
  const onChange = (e) => {
    updateMyData(rowIndex, e.target.innerText, columnId);
  };

  return (
    <div
      contentEditable={true}
      className="table-item-al"
      onChange={onChange}
      suppressContentEditableWarning={true}
      onBlur={onChange}
    >
      {value}
    </div>
  );
};
const Category = (props) => {
  const { cell } = props;
  return (
    <span className="text-muted no-edit table-item-al ">{cell.value}</span>
  );
};

const DescriptionCell = (props) => {
  return <EditableTextCell {...props} />;
};

const OrderCell = (props) => {
  return <OrderArrow {...props} />;
};

const ActionCell = (props) => {
  return <DeleteAction {...props} />;
};

const CategoryCell = (props) => {
  return <Category {...props} />;
};

const UneditableCell = (props) => {
  return (
    <span className="text-muted no-edit table-item-al ">{props.value}</span>
  );
};

const EditActionCell = (props) => {
  return <EditAction {...props} />;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    width: "100%",
    textAlign: "left",
  },
  formControl: {
    width: "100%",
  },
  checkbox: {
    marginLeft: "5px",
  },
}));

function SongListCategory(props) {
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const [rowIndex, setRowIndex] = useState(-1);
  const [cellValue, setCellValue] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const [selected, setSelected] = useState({});
  const [changes, setChanges] = useState({});
  const [confirmationSave, setConfirmationSave] = useState(false);
  const [confirmationDelete, setConfirmationDelete] = useState(false);

  const toggleDeleteConfirmationModal = (rowIndexx, details) => {
    setConfirmationDelete(!confirmationDelete);
    if (!confirmationDelete) {
      setSelected({ ...details });
      setRowIndex(rowIndexx);
    } else {
      setSelected({});
      setRowIndex(-1);
    }
  };

  const toggleEditModal = (rowIndexx, details) => {
    setModalEdit(!modalEdit);
    if (!modalEdit) {
      setSelected({ ...details });
      setRowIndex(rowIndexx);
    } else {
      setChanges({});
      setSelected({});
      setRowIndex(-1);
    }
  };

  const columns = React.useMemo(() => {
    return [
      {
        Header: "",
        accessor: "order",
        Cell: OrderCell,
      },
      {
        Header: "",
        id: "edit",
        Cell: EditActionCell,
      },
      {
        Header: "ID",
        accessor: "id",
        Cell: UneditableCell,
      },
      {
        Header: "orderInCategory",
        accessor: "orderInCategory",
        Cell: UneditableCell,
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: DescriptionCell,
      },
      {
        Header: "Singer",
        accessor: "singer",
        Cell: DescriptionCell,
      },
      {
        Header: "",
        id: "action",
        Cell: ActionCell,
      },
    ];
  });

  const data = props.data ? props.data : [];

  const updateMyData = (rowIndex, newValue, field) => {
    props.saveChanges(rowIndex, field ? field : -1, newValue);
  };

  const removeRow = (rowIndex) => {
    props.setSongs(props.data.filter((row, index) => index !== rowIndex));
    props.deleteItem(rowIndex);
  };

  const reorderData = (startIndex, endIndex) => {
    const newData = [...data];
    const [movedRow] = newData.splice(startIndex, 1);
    newData.splice(endIndex, 0, movedRow);
    newData.map((nd, index) => {
      nd.newOrderInCategory = index;
    });
    props.saveNewOrder(newData);
  };

  const changeField = (field, value) => {
    changes[field] = value;
    setChanges({ ...changes });
  };

  return (
    <>
      <Modal
        isOpen={modalEdit}
        className="modal-xl modal-dialog-centered"
        toggle={toggleEditModal}
      >
        <ModalHeader className="justify-content-center">
          {"Edit Song Details id: " + selected.id}
        </ModalHeader>
        {selected && (
          <ModalBody>
            <Row>
              <Col sm={12}>
                <Row>
                  {selected &&
                    Object.entries(selected).map((key, index) => {
                      return key[0] === "isPremium" ||
                        key[0] === "isEditorChoice" ||
                        key[0] === "isClean" ? (
                        <FormControlLabel
                          key={key[0] + "fl"}
                          className={classes.checkbox}
                          control={
                            <Checkbox
                              key={key[0] + "cb"}
                              size="small"
                              defaultChecked={key[1] === true}
                              onChange={(e) => {
                                changeField(key[0], e.target.checked);
                              }}
                              name={key[0]}
                              color="primary"
                            />
                          }
                          label={key[0]}
                        />
                      ) : (
                        key[0] != "id" &&
                          key[0] != "order" &&
                          key[0] != "categoryId" &&
                          key[0] != "orderInCategory" && (
                            <Col sm={6} className="mt-4" key={key[0] + "col"}>
                              <FormGroup key={key[0] + "fg"}>
                                <TextField
                                  key={key[0] + "fg"}
                                  defaultValue={key[1]}
                                  className={classes.formControl}
                                  label={key[0]}
                                  onChange={(e) => {
                                    changeField(key[0], e.target.value);
                                  }}
                                ></TextField>
                              </FormGroup>
                            </Col>
                          )
                      );
                    })}
                </Row>
              </Col>
            </Row>
          </ModalBody>
        )}
        <ModalFooter className="justify-content-center">
          <Button
            color="primary w-25 align-center mb-2"
            onClick={(e) => {
              setConfirmationSave(!confirmationSave);
            }}
          >
            Save
          </Button>
          <Button
            color="primary"
            className="align-center w-25 mb-2 ml-2"
            onClick={(e) => {
              //setConfirmationSave(!confirmation);
              toggleEditModal();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <ConfirmationModal
        confirmation={confirmationSave}
        toggleConfirmation={() => setConfirmationSave(!confirmationSave)}
        continueAction={(e) => {
          setConfirmationSave(!confirmationSave);
          updateMyData(rowIndex, changes);
          toggleEditModal();
        }}
        title={"Saving Changes on Song id: " + selected.id}
        body={JSON.stringify(changes, null, 2)}
      />

      <ConfirmationModal
        confirmation={confirmationDelete}
        toggleConfirmation={() => setConfirmationDelete(!confirmationDelete)}
        continueAction={(e) => {
          toggleDeleteConfirmationModal();
          removeRow(rowIndex);
        }}
        title={"Deleting id: " + selected.id}
      />
      <Table
        columns={columns}
        data={data}
        updateMyData={updateMyData}
        reorderData={reorderData}
        removeRow={removeRow}
        toggleEditModal={toggleEditModal}
        toggleDeleteConfirmationModal={toggleDeleteConfirmationModal}
        saveChanges={props.saveChanges}
      />
    </>
  );
}

export default SongListCategory;
