import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  FormGroup,
  Container,
  Col,
  Alert,
} from "reactstrap";

// core components
import PanelNavbar from "components/Navbars/PanelNavbar.js";
import { isEmpty } from "../../functions/validations";
import CRUD from "gapi/crud";

function Login(props) {
  const history = useHistory();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const [isUsernameValid, setIsUsernameValid] = useState({});
  const [isPassValid, setIsPassValid] = useState({});

  const [isHide, setHide] = useState(true);

  React.useEffect(() => {
    document.body.style.overflow = "hidden";
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    const isUsernameValid = isEmpty(username, "Geçersiz kullanıcı adı");
    setIsUsernameValid(isUsernameValid);
    const isPassValid = isEmpty(password, "Geçersiz şifre");
    setIsPassValid(isPassValid);

    if (isUsernameValid.isValid && isPassValid.isValid) {
      props.setSpinner(true);
      CRUD.login.post(
        "",
        { username, password },
        (res) => {
          localStorage.setItem("mqtoken", res.data.token);
          localStorage.setItem("mquser", JSON.stringify(res.data.user));
          setTimeout(() => {
            props.setSpinner(false);
          }, 500);
          history.push("/admin/environments");
        },
        (err) => {
          console.log(err);
          setTimeout(() => {
            props.setSpinner(false);
          }, 500);
          setError(err);
        }
      );
    } else {
      setError(isUsernameValid.message || isPassValid.message);
    }
  };

  const hideSwitch = () => {
    setHide(!isHide);
  };

  return (
    <>
      <PanelNavbar />
      <div
        className="page-header login-page-header clear-filter"
        filter-color="main"
      >
        <div className="page-header-image"></div>
        <div className="content login-content">
          <Container>
            <Col className="ml-auto mr-auto" lg="6">
              <Card className="card-login card-plain">
                <Form onSubmit={handleOnSubmit}>
                  <CardHeader className="text-center"></CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Input
                        placeholder={"Username"}
                        type="text"
                        value={username}
                        className={isUsernameValid.className}
                        onChange={(e) => {
                          setUsername(e.target.value);
                          setIsUsernameValid({});
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        placeholder={"Password"}
                        className={isPassValid.className}
                        type={isHide ? "password" : "input"}
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          setIsPassValid({});
                        }}
                      ></Input>
                      <i
                        className={
                          "password__show fa fa-eye" + (isHide ? "-slash" : "")
                        }
                        onClick={hideSwitch}
                      />
                    </FormGroup>
                    {error && (
                      <Alert color="danger">
                        <span>{error}</span>
                      </Alert>
                    )}
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      block
                      className="mt-2 mb-2"
                      color="environment"
                      size="md"
                      type="submit"
                    >
                      Login
                    </Button>
                  </CardFooter>
                </Form>
              </Card>
            </Col>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Login;
