import React, { useState } from "react";

// reactstrap components
import { Row } from "reactstrap";

import PanelHeader from "components/Headers/PanelHeader.js";

function Environments(props) {
  const [selectedEnvironmentId, setSelectedEnvironmentId] = useState(null);
  const [name, setName] = useState("");

  const id = localStorage.getItem("selected-environment-id");
  React.useEffect(() => {
    setSelectedEnvironmentId(id);
  }, [id]);

  React.useEffect(() => {
    setName(props.selectedEnvironment.name);
  }, [props.selectedEnvironment.name]);

  return (
    <>
      <PanelHeader
        content={
          <div className="header">
            <h5 className="title mb-">ENVIRONMENTS</h5>
          </div>
        }
      />
      <div className="content">
        <Row>{props.Dropdown(true)}</Row>
      </div>
    </>
  );
}

export default Environments;
