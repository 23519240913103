import React, { useState } from "react";
import { useTable, useSortBy } from "react-table";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import BTable from "react-bootstrap/Table";

import ConfirmationModal from "components/Modals/Confirmation";
// reactstrap components
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  FormGroup,
  Col,
} from "reactstrap";

function Table({
  columns,
  data,
  updateMyData,
  removeRow,
  toggleEditModal,
  toggleDeleteConfirmationModal,
  saveChanges,
}) {
  const table = useTable(
    {
      columns,
      data,
      updateMyData,
      removeRow,
      toggleEditModal,
      toggleDeleteConfirmationModal,
      saveChanges,
    },
    useSortBy
  );

  const { getTableProps, headerGroups, prepareRow, rows } = table;

  return (
    <>
      <BTable
        striped
        bordered
        size="sm"
        {...getTableProps()}
        className="environment-detail-list"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  {column.Header !== "" && (
                    <span style={{ color: "#f63a80" }}>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <i
                            className="fa fa-angle-down"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i className="fa fa-angle-up" aria-hidden="true"></i>
                        )
                      ) : (
                        <>
                          <i className="fa fa-angle-up" aria-hidden="true"></i>
                          <i
                            className="fa fa-angle-down"
                            aria-hidden="true"
                          ></i>
                        </>
                      )}
                    </span>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
    </>
  );
}

const DeleteAction = (props) => {
  const { row, toggleDeleteConfirmationModal } = props;
  return (
    <>
      {/* <UncontrolledTooltip delay={0} target={'ttd-' + row.id}>
        Ürün Grubunu Siler. Altında ürün olan Ürün Gruplarını silemezsiniz.
      </UncontrolledTooltip> */}
      <span
        id={"ttd-" + row.id}
        onClick={() => toggleDeleteConfirmationModal(row.index, row.original)}
        role="img"
        aria-label="delete"
        className="action-item"
      >
        <i className="fa fa-times"></i>
      </span>
    </>
  );
};

const EditAction = (props) => {
  const { row, toggleEditModal } = props;
  return (
    <>
      {/* <UncontrolledTooltip delay={0} target={'ttd-' + row.id}>
        Ürün Grubunu Siler. Altında ürün olan Ürün Gruplarını silemezsiniz.
      </UncontrolledTooltip> */}
      <span
        id={"ttd-" + row.id}
        onClick={() => toggleEditModal(row.index, row.original)}
        role="img"
        aria-label="edit"
        className="action-item"
      >
        <i className="fa fa-edit"></i>
      </span>
    </>
  );
};

const EditableTextCell = (props) => {
  const { column, row, cell, updateMyData } = props;
  const value = cell.value;
  const rowIndex = row.index;
  const columnId = column.id;
  const onChange = (e) => {
    updateMyData(rowIndex, e.target.innerText, columnId);
  };

  return (
    <div
      contentEditable={true}
      className="table-item-al"
      onChange={onChange}
      suppressContentEditableWarning={true}
      onBlur={onChange}
    >
      {value}
    </div>
  );
};

const UneditableCell = (props) => {
  return (
    <span className="text-muted no-edit table-item-al ">{props.value}</span>
  );
};

const ActionCell = (props) => {
  return <DeleteAction {...props} />;
};
const EditActionCell = (props) => {
  return <EditAction {...props} />;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    width: "100%",
    textAlign: "left",
  },
  formControl: {
    width: "100%",
  },
}));

function CategoryList(props) {
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const [rowIndex, setRowIndex] = useState(-1);
  const [cellValue, setCellValue] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const [selected, setSelected] = useState({});
  const [changes, setChanges] = useState({});
  const [confirmationSave, setConfirmationSave] = useState(false);
  const [confirmationDelete, setConfirmationDelete] = useState(false);

  const toggleEditModal = (rowIndexx, details) => {
    setModalEdit(!modalEdit);
    if (!modalEdit) {
      setSelected({ ...details });
      setRowIndex(rowIndexx);
    } else {
      setChanges({});
      setSelected({});
      setRowIndex(-1);
    }
  };

  const toggleDeleteConfirmationModal = (rowIndexx, details) => {
    setConfirmationDelete(!confirmationDelete);
    if (!confirmationDelete) {
      setSelected({ ...details });
      setRowIndex(rowIndexx);
    } else {
      setSelected({});
      setRowIndex(-1);
    }
  };

  const columns = React.useMemo(() => {
    return [
      {
        Header: "",
        id: "edit",
        Cell: EditActionCell,
      },
      {
        Header: "id",
        accessor: "id",
        Cell: UneditableCell,
      },
      {
        Header: "name EN",
        accessor: "nameEn",
        Cell: UneditableCell,
      },
      {
        Header: "emojiLink",
        accessor: "emojiLink",
        Cell: EditableTextCell,
      },
      {
        Header: "",
        id: "action",
        Cell: ActionCell,
      },
    ];
  });

  const updateMyData = (rowIndex, newValue, field) => {
    props.saveChanges(rowIndex, field ? field : -1, newValue);
  };

  const removeRow = (rowIndex) => {
    props.setSongs(props.data.filter((row, index) => index !== rowIndex));
    props.deleteItem(rowIndex);
  };

  const changeEmojiLink = (value) => {
    changes.emojiLink = value;
    setChanges({ ...changes });
  };

  const changeName = (key, value) => {
    changes.name = changes.name ? changes.name : {};
    changes.name[key] = value;
    setChanges({ ...changes });
  };

  const changeOrder = (key, value) => {
    changes.order = changes.order ? changes.order : {};
    changes.order[key] = value;
    setChanges({ ...changes });
  };

  return (
    <>
      <Modal
        isOpen={modalEdit}
        className="modal-xl modal-dialog-centered"
        toggle={toggleEditModal}
      >
        <ModalHeader className="justify-content-center">
          Edit Category Details General
        </ModalHeader>
        {selected && (
          <ModalBody>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <TextField
                    id="id"
                    value={selected.id}
                    disabled={true}
                    label={"ID"}
                  ></TextField>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <TextField
                    id="emojiLink"
                    defaultValue={selected.emojiLink}
                    className={classes.formControl}
                    label={"Emoji Link"}
                    onChange={(e) => {
                      changeEmojiLink(e.target.value);
                    }}
                  ></TextField>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <h6>Names</h6>
                <Row>
                  {selected.name &&
                    Object.entries(selected.name).map((key, index) => {
                      return (
                        <Col sm={3} className="mt-4" key={key[0] + "col"}>
                          <FormGroup key={key[0] + "fg"}>
                            <TextField
                              key={key[0] + "tf"}
                              defaultValue={key[1]}
                              className={classes.formControl}
                              label={key[0]}
                              onChange={(e) => {
                                changeName(key[0], e.target.value);
                              }}
                            ></TextField>
                          </FormGroup>
                        </Col>
                      );
                    })}
                </Row>
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <h6>Orders</h6>
                <Row>
                  {selected.name &&
                    Object.entries(selected.order).map((key, index) => {
                      return (
                        <Col sm={3} className="mt-4">
                          <FormGroup key={"a" + index}>
                            <TextField
                              key={"c" + index}
                              type="number"
                              defaultValue={key[1]}
                              className={classes.formControl}
                              label={key[0]}
                              onChange={(e) => {
                                changeOrder(key[0], e.target.value);
                              }}
                              InputProps={{
                                inputProps: { min: 0, max: selected.maxOrder },
                              }}
                            ></TextField>
                          </FormGroup>
                        </Col>
                      );
                    })}
                </Row>
              </Col>
            </Row>
          </ModalBody>
        )}
        <ModalFooter className="justify-content-center">
          <Button
            color="primary w-25 align-center mb-2"
            onClick={(e) => {
              setConfirmationSave(!confirmationSave);
            }}
          >
            Save
          </Button>
          <Button
            color="primary"
            className="align-center w-25 mb-2 ml-2"
            onClick={(e) => {
              //setConfirmationSave(!confirmation);
              toggleEditModal();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <ConfirmationModal
        confirmation={confirmationSave}
        toggleConfirmation={() => setConfirmationSave(!confirmationSave)}
        continueAction={(e) => {
          setConfirmationSave(!confirmationSave);
          updateMyData(rowIndex, changes);
          toggleEditModal();
        }}
        title={"Saving Changes on Category " + selected.nameEn}
        body={JSON.stringify(changes, null, 2)}
      />

      <ConfirmationModal
        confirmation={confirmationDelete}
        toggleConfirmation={() => setConfirmationDelete(!confirmationDelete)}
        continueAction={(e) => {
          toggleDeleteConfirmationModal();
          removeRow(rowIndex);
        }}
        title={"Deleting " + selected.nameEn}
      />

      <Table
        columns={columns}
        data={props.data}
        updateMyData={updateMyData}
        removeRow={removeRow}
        toggleEditModal={toggleEditModal}
        toggleDeleteConfirmationModal={toggleDeleteConfirmationModal}
        saveChanges={props.saveChanges}
      />
    </>
  );
}

export default CategoryList;
